import { useProfileContext } from "contexts/ProfileContext";
import { useSocialContext } from "SocialNetwork/context/SocialContext";

export default function GetId() {
  const { me } = useProfileContext();
  const { companySelected } = useSocialContext();

  if (me && me?.type === "COMPANY") {
    return me?.profile_id;
  }

  if (companySelected) {
    return companySelected;
  }

  if (me && me?.companies && me?.companies.length) {
    return me?.companies[0].id;
  }
}
