import React, { useMemo } from "react";
import { Route as ReactDOMRoute } from "react-router-dom";
import { RouteProps } from "interfaces/RouteProps";
import { useSocialContext } from "SocialNetwork/context/SocialContext";

const Route: React.FC<React.PropsWithChildren<RouteProps>> = ({
  isPrivate = false,
  component: Component,
  isAuthenticated = false,
  isPerson = false,
  permissionsRequired,
  ...rest
}) => {
  const isComponentAccessible = useMemo(
    () => (isAuthenticated && isPrivate) || (!isAuthenticated && !isPrivate),
    [isAuthenticated, isPrivate],
  );

  return <ReactDOMRoute exact {...rest} render={() => (isComponentAccessible ? <Component /> : <></>)} />;
};

export default Route;
