import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { links } from "safira-app/config/links";
import { ProfileData, Profile, ProfileCompany, ProfilePerson, Phone, Address } from "interfaces/ProfileGlobal";
import { MeProps } from "safira-app/interfaces/Me";
import { AxiosResponse } from "axios";
import { PhoneType } from "utils/profile";
import api from "services/api";
import { sendProfileCompany, sendProfilePerson } from "SocialNetwork/functions/account";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export interface PhoneToSend {
  phone_number: string;
  profile_type: "PERSON" | "COMPANY";
  phone_type: PhoneType;
}

export interface PhoneResponse {
  phone_number: string;
  profile_id: string;
  profile_type: string;
  phone_type: string;
  id: string;
  created_at: string;
  updated_at: String;
}

export interface ProfileGlobalProps {
  me: MeProps;
  address: Address | null;
  setMe: SetState<MeProps>;
  person: ProfilePerson | null;
  company: ProfileCompany | null;
  common: Profile;
  profile_type?: "PERSON" | "COMPANY";
  phone: Phone[];
  updateProfile: (newProfileData: any) => void;
  updateProfilePerson: (newData: ProfilePerson) => void;
  updateProfileCompany: (newData: ProfileCompany) => void;
  updateCommon: (newCommonData: Profile) => Promise<any>;
}

export const ProfileContext = createContext({} as ProfileGlobalProps);

export const ProfileContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [company, setCompany] = useState<ProfileCompany | null>();
  const [person, setPerson] = useState<ProfilePerson | null>();
  const [common, setCommon] = useState<Profile | null>();
  const [phone, setPhone] = useState<Phone[] | null>();
  const [address, setAddress] = useState<Address[] | null>();
  const [profileType, setProfileType] = useState<"PERSON" | "COMPANY" | null>();

  const [me, setMe] = useState<MeProps>();

  useEffect(() => {
    api.get(`${links.api.schedule}/auth/me`).then(response => {
      setMe(response.data);
    });
  }, []);

  const updateProfilePerson = useCallback(
    (newData: ProfilePerson) => {
      const newPerson = { ...person, ...newData } as ProfilePerson;
      setPerson(newPerson);
    },
    [person],
  );

  const updateProfileCompany = useCallback(
    (newData: ProfileCompany) => {
      const newCompany = { ...company, ...newData } as ProfileCompany;
      setCompany(newCompany);
    },
    [company],
  );

  const updateProfile = useCallback(
    (newProfileData: any) => {
      switch (newProfileData.type) {
        case "PERSON":
          updateProfilePerson(newProfileData as ProfilePerson);
          break;

        case "COMPANY":
          updateProfileCompany(newProfileData as ProfileCompany);
          break;

        default:
          break;
      }
    },
    [updateProfilePerson, updateProfileCompany],
  );

  const updateCommon = useCallback(
    (newCommonData: Profile) => {
      return new Promise((resolve, reject) => {
        switch (common?.type) {
          case "PERSON":
            sendProfilePerson(newCommonData)
              .then(profilePersonResponse => {
                setCommon(oldCommon => ({ ...oldCommon, ...newCommonData }));
                resolve(profilePersonResponse);
              })
              .catch(err => reject(err));
            break;
          case "COMPANY":
            sendProfileCompany(newCommonData)
              .then(profileCompanyResponse => {
                setCommon(oldCommon => ({ ...oldCommon, ...newCommonData }));
                resolve(profileCompanyResponse);
              })
              .catch(err => reject(err));
            break;

          default:
            reject(new Error("Profile type not found"));
            break;
        }
      });
    },
    [common],
  );

  useEffect(() => {
    const route = `${links.api.social}/profile`;
    // eslint-disable-next-line
    (async function () {
      try {
        const response: AxiosResponse<ProfileData> = await api.get(route);
        const { data } = response;

        updateProfile(response.data.profile);

        setCommon(data.profile);
        setPhone(data.phone);
        setAddress(data.address);
        setProfileType(data.profile.type);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []); // eslint-disable-line

  const context = useMemo(
    () =>
      ({
        get person() {
          if (person) return person;
          return {};
        },
        get company() {
          if (company) return company;
          return {};
        },
        get common() {
          if (common) return common;
          return {};
        },
        profile_type: profileType,
        phone,
        address,
        me,
        setMe,
        updateCommon,
        updateProfile,
        updateProfilePerson,
        updateProfileCompany,
      } as ProfileGlobalProps),
    [
      company,
      common,
      profileType,
      person,
      phone,
      address,
      me,
      setMe,
      updateCommon,
      updateProfile,
      updateProfilePerson,
      updateProfileCompany,
    ],
  );

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};

export function useProfileContext() {
  const context = useContext(ProfileContext);
  return context;
}
