import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { About, Address, ProfileData, Experience } from "interfaces/ProfileGlobal";
import api from "services/api";
import { links } from "safira-app/config/links";
import { PublicationProps } from "interfaces/Publications";
import { SuggestionData } from "interfaces/Suggestion";
import Cookies from "js-cookie";
import { useProfileContext } from "contexts/ProfileContext";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface propsSocialContext {
  children: ReactNode;
}

export interface SocialContextProps {
  about: About;
  setAbout: SetState<About>;
  profiles: ProfileData;
  setProfiles: SetState<ProfileData>;
  getProfileData: (username: string) => Promise<ProfileData>;
  updateProfile: () => void;
  publication: PublicationProps[];
  setPublication: SetState<PublicationProps[]>;
  suggestions: SuggestionData;
  setSuggestions: SetState<SuggestionData>;
  experiences: Experience[];
  setExperiences: SetState<Experience[]>;
  address: Address[];
  setAddress: SetState<Address[]>;
  formations: any;
  setFormations: Function;
  connections: any;
  setConnections: Function;
  companySelected: any;
  setCompanySelected: Function;
}

const SocialContext = createContext<SocialContextProps>({} as SocialContextProps);

export default function SocialProvider({ children }: propsSocialContext) {
  const { me } = useProfileContext();

  const [about, setAbout] = useState({} as About);
  const [formations, setFormations] = useState<any>([]);
  const [publication, setPublication] = useState<any>([]);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [connections, setConnections] = useState<any>([]);
  const [address, setAddress] = useState([] as Address[]);
  const [companySelected, setCompanySelected] = useState<any>();
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [profiles, setProfiles] = useState<ProfileData>({} as ProfileData);

  function updateProfile() {
    getProfileData(profiles.profile.username || "").then(response => {
      setProfiles(response);
    });
  }

  async function getProfileData(username: string) {
    const response = await api.get(`${links.api.social}/profile/username/${username}`);
    return response.data as ProfileData;
  }

  useEffect(() => {
    const company_selected = Cookies.get("companySelected");
    const userHasThisCompany =
      me?.companies && company_selected ? me?.companies.map((pos: any) => pos.id).includes(company_selected) : false;
    if (company_selected && userHasThisCompany) {
      setCompanySelected(company_selected);
    }
  }, []);

  return (
    <SocialContext.Provider
      value={{
        get about() {
          return about ? about : ({} as About);
        },
        setAbout,
        profiles,
        setProfiles,
        getProfileData,
        updateProfile,
        publication,
        setPublication,
        suggestions,
        setSuggestions,
        experiences,
        setExperiences,
        address,
        setAddress,
        formations,
        setFormations,
        connections,
        setConnections,
        companySelected,
        setCompanySelected,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
}

export function useSocialContext() {
  return useContext<SocialContextProps>(SocialContext);
}
