import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Routes from "routes";

import { PublicationProvider } from "SocialNetwork/context/PublicationContext";
import { FeedbackProviderContext } from "NewFeedback/contexts/FeedbackContext";
import { EndomarketingProviderContext } from "Endomarketing/contexts/EndomarketingContext";
import { GroupProvider } from "SocialNetwork/context/GroupContext";
import { ListPublicationsProvider } from "SocialNetwork/components/Publications/listPublicationsContext";
import Tracker from "@openreplay/tracker";
import GlobalStyle from "./styles/global";
import { useAuth } from "safira-app/hooks/useAuth";
import SocialContext from "SocialNetwork/context/SocialContext";
import { PermissionsProvider } from "./safira-app/contexts/Permissions";

const isProduction = JSON.parse(process.env.REACT_APP_PRODUCTION!);

const Application: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuth();

  const sessionInfo = sessionStorage.getItem("openreplay-session");

  useEffect(() => {
    if (!!isProduction && user && !sessionInfo) {
      const tracker = new Tracker({
        projectKey: "Jn5iTG5DsiZwMofN6xNO",
        ingestPoint: "https://logging.incicle.com/ingest",
        obscureInputEmails: false,
        obscureTextEmails: false,
        defaultInputMode: 0,
        network: {
          captureInIframes: false,
          capturePayload: true,
          failuresOnly: false,
          ignoreHeaders: false,
          sessionTokenHeader: false,
        },
      });

      tracker.start({
        userID: user.email,
        metadata: {
          module: "Rede Social",
          profileID: user.profile_id,
          userType: user.type,
          username: user.username,
        },
      });

      localStorage.setItem("openreplay-session", "created");
    }
  }, []);

  return (
    <SocialContext>
      <PermissionsProvider>
        <FeedbackProviderContext>
          <EndomarketingProviderContext>
            <PublicationProvider>
              <GroupProvider>
                <ListPublicationsProvider>
                  <ToastProvider autoDismiss>
                    <GlobalStyle />
                    <Router>
                      <Routes />
                    </Router>
                  </ToastProvider>
                </ListPublicationsProvider>
              </GroupProvider>
            </PublicationProvider>
          </EndomarketingProviderContext>
        </FeedbackProviderContext>
      </PermissionsProvider>
    </SocialContext>
  );
};

export default Application;
