import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "services/i18n";
import App from "./App";
import { StrictMode } from "react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n} defaultNS={"translation"}>
      <App />
    </I18nextProvider>
  </StrictMode>,
);
