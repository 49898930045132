import { Profile } from "interfaces/ProfileGlobal";
import api from "services/api";
import { links } from "safira-app/config/links";

interface CompanyResponse {
  id: string;
  name: string;
  foundation_date: string;
  legal_representative: string;
  social_subtitle: string;
  social_location: string;
  social_cover: string;
}

interface PersonResponse {}

export async function sendProfilePerson(newProfileData: Profile) {
  const response = await api.put(`${links.api.core}/persons/me`, newProfileData);
  return response.data as PersonResponse;
}

export async function sendProfileCompany(newProfileData: Profile) {
  const response = await api.put(`${links.api.core}/companies/me`, newProfileData);
  return response.data as CompanyResponse;
}

export {};

export async function registerCompany(data) {
  const response = await api.post(`${links.api.core}/register/company`, data);
  return response;
}

export async function registerPartner(partnerName: string) {
  const response = await api.post(`${links.api.core}/partners`, {
    name: partnerName,
  });
  return response;
}

export async function getPartners() {
  const response = await api.get(`${links.api.core}/partners`);
  return response;
}
