import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import { AuthProvider } from "safira-app/contexts/AuthContext";
import Application from "./Application";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ProfileContextProvider } from "contexts/ProfileContext";

const isProduction = JSON.parse(process.env.REACT_APP_PRODUCTION!);
const trackingGA = "G-7Q7CBPZCNS";

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    ReactGA4.initialize(trackingGA, { testMode: Boolean(!isProduction) });
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ProfileContextProvider>
          <Application />
        </ProfileContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
